import React, { createContext, PropsWithChildren, useEffect, useState } from "react";
import { ConfigProvider, theme } from "antd";
import { parseCookies, setCookie } from "nookies";

type ColorModeContextType = {
  mode: string;
  setMode: (mode: string) => void;
};

const THEME = "theme";

export const ColorModeContext = createContext<ColorModeContextType>({} as ColorModeContextType);

export const ColorModeContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [isMounted, setIsMounted] = useState(false);
  const [mode, setMode] = useState("light");

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (isMounted) {
      setMode(parseCookies()[THEME]);
    }
  }, [isMounted]);

  const setColorMode = () => {
    if (mode === "dark") {
      setMode("light");
      setCookie(null, THEME, "light");
    } else {
      setMode("dark");
      setCookie(null, THEME, "dark");
    }
  };

  const { darkAlgorithm, defaultAlgorithm } = theme;

  return (
    <ColorModeContext.Provider
      value={{
        setMode: setColorMode,
        mode,
      }}
    >
      <ConfigProvider
        theme={{
          algorithm: mode === "dark" ? darkAlgorithm : defaultAlgorithm,
        }}
      >
        {children}
      </ConfigProvider>
    </ColorModeContext.Provider>
  );
};
